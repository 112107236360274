import { alpha } from '@mui/system';

export default theme => ({
  remediationButton: props => ({
    color: props.altColor || props.color,
    backgroundColor: alpha(props.color, 0.15),
    height: props.small ? theme.spacing(3) : theme.spacing(3.5),
    ...(!props.isInteractive && { pointerEvents: 'none' }),
    '&:hover': props => ({
      backgroundColor: alpha(props.color, theme.palette.action.hoverOpacity)
    })
  }),
  labelContainer: props => ({
    ...(!props.isInteractive && { width: theme.spacing(15) }),
    display: 'flex',
    alignItems: 'center'
  }),
  buttonTypography: {
    fontSize: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.spacing(1.75)
  },
  circleIcon: props => ({
    color: props.color
  })
});

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import makeStyles from '@mui/styles/makeStyles';
import NRKEVTwoToneIcon from '../../../../NRIcons/NRKEVTwoToneIcon';
import NRKeyValueDataDisplay from '../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import moment from 'moment/moment';
import { useTheme } from '@mui/material/styles';
import { CONSTANTS } from '../../../../constants/constants';
const { MONTH_DAY_YEAR_SHORT } = CONSTANTS.DATE_FORMAT;
import NRCISAKevAlertDetailsStyles from './NRCISAKevAlertDetails.styles';
const useStyles = makeStyles(NRCISAKevAlertDetailsStyles);

export function CISAKevDetails({ kevData, dueDateColor }) {
  const { action, dateAdded, dueDate, name } = kevData;
  return (
    <>
      <NRKeyValueDataDisplay showDash minWidth={'25px'} valueMaxWidth={'fit-content'} keyWidth={'auto'} dataKey={'Name'} value={name} />
      <NRKeyValueDataDisplay
        showDash
        minWidth={'25px'}
        valueMaxWidth={'fit-content'}
        keyWidth={'auto'}
        dataKey={'Date Added to List'}
        value={moment(dateAdded).format(MONTH_DAY_YEAR_SHORT)}
      />
      <NRKeyValueDataDisplay
        showDash
        wrapLine
        minWidth={'100px'}
        valueMaxWidth={'fit-content'}
        keyWidth={'auto'}
        dataKey={'Required Action'}
        value={action}
      />
      <NRKeyValueDataDisplay
        valueAltColor={dueDateColor}
        showDash
        minWidth={'25px'}
        valueMaxWidth={'fit-content'}
        keyWidth={'auto'}
        dataKey={'CISA Due Date'}
        value={moment(dueDate).format(MONTH_DAY_YEAR_SHORT)}
      />
    </>
  );
}

function NRCISAKevAlertDetails({ data }) {
  const theme = useTheme();
  const classes = useStyles();

  const isPastDueDate = moment(data?.dateAdded).isBefore(new Date());

  return (
    <Alert severity="error" icon={<NRKEVTwoToneIcon width="24px" height="24px" />} classes={{ root: classes.alert }}>
      <AlertTitle classes={{ root: classes.body2 }}>{'This vulnerability is on the CISA Known Exploited Vulnerability list'}</AlertTitle>
      <CISAKevDetails kevData={data} dueDateColor={isPastDueDate ? theme.palette.error.main : null} />
    </Alert>
  );
}
export default NRCISAKevAlertDetails;

export default theme => ({
  modalMainContainer: props => ({
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    color: theme.palette.background.paper,
    position: 'absolute',
    width: props.width,
    maxHeight: '92vh',
    overflowY: 'auto',
    /* Hide scrollbar for IE, Edge and Firefox */
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none',
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }),
  cardHeader: {
    padding: theme.spacing(2, 3)
  },
  cardHeaderTitle: {
    fontSize: 20
  },
  cardContent: {
    padding: theme.spacing(3, 3, 6),
    fontSize: 16,
    lineHeight: '24px'
  },
  cardActions: {
    padding: theme.spacing(1.75, 3),
    justifyContent: 'flex-end'
  },
  cardActionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2)
    }
  }
});

import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { includesPermission } from '../../utils/utils';

const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
const permissionsNamespace = auth0Namespace + 'permissions';

function WithPermissions({ children, permission }) {
  const [hasPermission, setHastPermission] = useState(false);
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function asyncFun() {
      const token = await getIdTokenClaims();
      setHastPermission(includesPermission(token[permissionsNamespace], permission));
    }
    asyncFun();
  }, []);

  return <>{hasPermission && children}</>;
}

export default WithPermissions;

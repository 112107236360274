import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import NRHighlightedTextStyles from './NRHighlightedText.styles';
const useStyles = makeStyles(NRHighlightedTextStyles);

function NRHighlightedText({
  style,
  text,
  color,
  backgroundColor,
  icon,
  fontSize,
  lineHeight,
  highlightAll = false,
  alphaValue = 0.15,
  tooltipContent = '',
  minWidth = null,
  showBorder = false,
  justifyContent = null,
  handleOnClick,
  textAlphaValue = 1
}) {
  const props = useMemo(
    () => ({
      color,
      backgroundColor,
      fontSize,
      lineHeight,
      alphaValue,
      minWidth,
      showBorder,
      justifyContent,
      textAlphaValue,
      text
    }),
    [color, backgroundColor, fontSize, lineHeight, alphaValue, minWidth, showBorder, justifyContent, textAlphaValue, text]
  );

  const classes = useStyles(props);

  return (
    <Tooltip title={tooltipContent} placement="top">
      <div style={style} className={classes.container} onClick={handleOnClick}>
        {!highlightAll && icon}
        <span className={classes.textStyleBase}>
          {highlightAll && icon}
          {text}
        </span>
      </div>
    </Tooltip>
  );
}

export default NRHighlightedText;

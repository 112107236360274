import React, { useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import NRKeyValueDataDisplayStyles from './NRKeyValueDataDisplay.styles';
const useStyles = makeStyles(NRKeyValueDataDisplayStyles);

function NRKeyValueDataDisplay({
  uniqueKey,
  dataKey,
  value,
  keyWidth = '40%',
  minWidth = 168,
  valueMaxWidth = 100,
  showDash,
  showTooltip = false,
  tooltipValue,
  wrapLine = false,
  hasCopy = false,
  valueAltColor,
  endAdornment
}) {
  const classes = useStyles({ keyWidth, minWidth, valueMaxWidth, valueAltColor });
  const { enqueueSnackbar } = useSnackbar();
  const valuePrefix = showDash ? ' — ' : '';
  const key = uniqueKey || dataKey + value;

  const onCopyThumbprint = useCallback(
    (title, value) => {
      navigator.clipboard.writeText(value);
      enqueueSnackbar(`${title} copied!`, {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
    },
    [enqueueSnackbar]
  );

  return (
    <div key={key} className={classes.mainContainer}>
      {!wrapLine ? (
        <>
          <div className={classes.key}>{dataKey}</div>
          <Tooltip title={showTooltip ? tooltipValue || value : ''}>
            <div className={classes.value}>
              {valuePrefix}
              {value}
            </div>
          </Tooltip>
          {hasCopy && (
            <div className={classes.value}>
              <FileCopyOutlinedIcon className={classes.icon} onClick={() => onCopyThumbprint(dataKey, value)} />
            </div>
          )}
          {endAdornment && <div className={classes.endAdornment}>{endAdornment}</div>}
        </>
      ) : (
        <div>
          <span className={classes.key}>{dataKey}</span>
          <Tooltip title={showTooltip ? tooltipValue || value : ''}>
            <span className={classes.oneLinerValue}>
              {valuePrefix}
              {value}
            </span>
          </Tooltip>
          {hasCopy && (
            <div className={classes.value}>
              <FileCopyOutlinedIcon className={classes.icon} onClick={() => onCopyThumbprint(dataKey, value)} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NRKeyValueDataDisplay;

export default theme => ({
  mainContainer: {
    ...theme.typography.body2,
    display: 'flex',
    margin: theme.spacing(0.5, 0),
    '&:first-child': {
      marginTop: 0
    }
  },
  key: {
    textTransform: 'capitalize',
    width: props => props.keyWidth,
    minWidth: props => props.minWidth,
    whiteSpace: 'nowrap'
  },
  value: {
    color: props => props.valueAltColor || theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(0.5),
    maxWidth: props => props.valueMaxWidth,
    cursor: 'default'
  },
  oneLinerValue: {
    color: props => props.valueAltColor || theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5)
  },
  icon: {
    width: '0.875rem',
    height: '0.875rem',
    marginLeft: 6,
    marginBottom: -2,
    cursor: 'pointer',
    color: theme.palette.text.icon
  },
  endAdornment: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1)
  }
});

import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import { useEffect, useState } from 'react';
import NRHelpDrawerStyles from './NRHelpDrawer.styles';
import NRHelpDrawerKnob from './NRHelpDrawerKnob/NRHelpDrawerKnob';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import routes from '../../routes';
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';
import 'github-markdown-css';

const useStyles = makeStyles(NRHelpDrawerStyles);

function comparePath(pathname, routePath) {
  if (routePath.includes(':')) {
    return pathname.startsWith(routePath.split(':')[0]);
  } else {
    return pathname === routePath;
  }
}

function NRHelpDrawer({ knobDistanceFromTop = '450px', knobOpenLabel, knobCloseLabel }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [displayHelpDrawer, setDisplayHelpDrawer] = useState(false);
  const [helpDrawerContent, setHelpDrawerContent] = useState(null);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    routes.map(route => {
      if (comparePath(pathname, route.path)) {
        setDisplayHelpDrawer((!!route?.displayHelpDrawer && !!route?.helpDrawerContent) || false);
        setHelpDrawerContent(!!route?.helpDrawerContent && route?.helpDrawerContent);
      }
    });
  }, [pathname]);

  function handleKnobClick(value) {
    setIsOpen(value);
  }

  return (
    <>
      {displayHelpDrawer && (
        <>
          <NRHelpDrawerKnob
            isOpen={isOpen}
            onclick={handleKnobClick}
            top={knobDistanceFromTop}
            label={knobOpenLabel || <HelpOutlineOutlinedIcon />}
          />
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor={'right'}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onKeyDown={event => {
              if (event.key === 'Escape') {
                setIsOpen(false);
              }
            }}
          >
            <div className={classes.mainContainer}>
              <NRHelpDrawerKnob
                isOpen={isOpen}
                onclick={handleKnobClick}
                top={knobDistanceFromTop}
                label={knobCloseLabel || <ChevronRightOutlinedIcon />}
              />
              <div className={classes.helpContent}>
                <div className="markdown-body">
                  <Markdown>{helpDrawerContent}</Markdown>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
}
export default NRHelpDrawer;

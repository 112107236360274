import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CONSTANTS } from '../../constants/constants';
import NRShowMoreStyles from './NRShowMore.styles';
const { SHOW_MORE, SHOW_LESS } = CONSTANTS;
const useStyles = makeStyles(NRShowMoreStyles);
function NRShowMore({ items, itemsToShowByDefault = 10, openByDefault = false, maxReached, stateToRefresh }) {
  const [showMore, setShowMore] = useState(openByDefault);
  const [showShowMoreLabel] = useState(items.length > itemsToShowByDefault);
  const [itemsToShow, setItemsToShow] = useState(items.slice(0, itemsToShowByDefault));
  const targetRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (showMore) {
      setItemsToShow(items);
    } else {
      setItemsToShow(items.slice(0, itemsToShowByDefault));
    }
  }, [showMore, stateToRefresh]);

  return (
    <div className={classes.mainContainer}>
      <div ref={targetRef} className={classes.itemsContainer}>
        {itemsToShow}
      </div>
      {showMore && maxReached}
      {showShowMoreLabel && (
        <div onClick={() => setShowMore(prev => !prev)} className={classes.showMore}>
          {showMore ? SHOW_LESS : SHOW_MORE}
        </div>
      )}
    </div>
  );
}
export default NRShowMore;

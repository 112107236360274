import { alpha } from '@mui/material/styles';

export default theme => ({
  container: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.justifyContent || 'initial'
  }),

  textStyleBase: props => ({
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, props.text ? 1 : 0.5),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    color: alpha(props.color, props.textAlphaValue),
    backgroundColor: alpha(props.backgroundColor || props.color, props.alphaValue),
    fontSize: props.fontSize || 'inherit',
    lineHeight: `${props.lineHeight || props.fontSize}px` || 'normal',
    minWidth: props.minWidth || 'auto',
    border: props.showBorder ? `1px solid ${alpha(props.color, props.textAlphaValue)}` : 'none',
    justifyContent: props.justifyContent || 'initial'
  })
});

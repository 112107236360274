export default theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemsContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  showMore: {
    ...theme.typography.body2,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.text.secondary
  }
});

import { alpha } from '@mui/material';

export default theme => ({
  rowHover: {
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.black, 0.04)} !important`
    }
  },
  overrideNoWrap: {
    '& > td': {
      whiteSpace: 'unset !important',
      wordBreak: 'break-word'
    }
  }
});

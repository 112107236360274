import makeStyles from '@mui/styles/makeStyles';
import NRSeverityComponentStyles from './NRSeverityComponent.styles';

const useStyles = makeStyles(NRSeverityComponentStyles);

function NRSeverityComponent({ value }) {
  const classes = useStyles({ value });

  return <div className={classes.severityContainer}>{value}</div>;
}
export default NRSeverityComponent;

import { Table } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import RowComponentStyles from './RowComponent.styles';
const useStyles = makeStyles(RowComponentStyles);
function RowComponent(props) {
  const classes = useStyles();

  return <Table.Row {...props} className={clsx(classes.rowHover, classes.overrideNoWrap)} />;
}

export default RowComponent;
